<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class="pt-0"
    >
      <news-grid />
    </v-col>
  </v-row>
</template>

<script>
import NewsGrid from '@/components/NewsGrid'

export default {
  name: 'News',
  components: {
    'news-grid': NewsGrid
  }
}
</script>
